<template>
  <div>
    <!-- 面包屑区域 -->
    <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card shadow="hover">
      <Title :title="title[0]"></Title>

      <!-- 列表区域  border加边框 stripe隔行变色 -->
      <el-table :data="blacklistInfo.blacklist" border stripe :header-cell-style="{textAlign: 'center'}" :cell-style="{textAlign: 'center'}">
        <el-table-column type="index" label="序号" width="50px"></el-table-column>
        <el-table-column label="拉黑时间" prop="ctime"></el-table-column>
        <el-table-column label="买手账号" prop="buyerid"></el-table-column>
          <el-table-column label="拉黑原因" prop="reason"></el-table-column>
        <el-table-column label="操作" width="230px">
          <template v-slot:default="scope">
            <!--解除拉黑-->
            <el-button type="danger" icon="el-icon-remove-outline" size="small"  @click="relieve(scope.row.buyerid)">解除</el-button>
          </template>
        </el-table-column>
      </el-table>


      <!-- 分页区域 -->
      <el-pagination
        :current-page="queryInfo.pagenum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="blacklistInfo.page_arr"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="blacklistInfo.blacknum"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      title: ["黑名单管理"],
      //面包屑数据
      breadcrumbData: [
        {
          id: 1,
          title: "好物电商",
          path: "",
          isClick: false
        },
        {
          id: 2,
          title: "黑名单管理",
          path: "blacklist",
          isClick: true
        }
      ],
      //获取黑名单列表的参数对象
      queryInfo: {
        // 当前的页数
        pagenum: 1,
        //当前每一页显示多少条数据
        pagesize: 10
      },
    };
  },
  computed:{
        ...mapState({
             blacklistInfo:state => state.blacklist.blacklistInfo
        })
  },
  methods:{
    //获取黑名单数据
    getBlacklist(){
        this.$store.dispatch('getBlacklist',{num:this.queryInfo.pagesize,page:this.queryInfo.pagenum})
    },

    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.queryInfo.pagenum = 1;
      this.getBlacklist();
    },
    
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getBlacklist();
    },

    //解除
    relieve(buyerid){
        // 打开提示弹窗
          this.$confirm('是否确认解除'+buyerid+'此买家账号？', '提示', {
               distinguishCancelAndClose: true,
               confirmButtonText: '解除',
               cancelButtonText: '取消',
               type: 'warning',
               center: true
        })
          .then(async () => {
               try{
                    await this.$store.dispatch('removeBlacklist',buyerid)
                    this.$message.success("解除成功！");
                    this.getBlacklist()
               }catch(error){
                    console.log(error.message)
               }
          })
          .catch(action => {
          });
    },
  },
  created(){
        this.getBlacklist()
  },
};
</script>

<style>
.el-card {
  margin: 20px 20px 50px 20px;
}
.el-table {
    margin-top: 20px;
}
.el-pagination {
  margin-top: 20px;
}
</style>